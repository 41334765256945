@import "~@flaticon/flaticon-uicons/css/all/all";

@tailwind base;
@tailwind components;
@tailwind utilities;

i{
    display: flex;
    justify-content: center;
    align-content: center
}